import React from 'react';
import { Outlet } from "react-router-dom";
import Header from './components/Header';
import './css/Common.css'



function Layout() {
  return (
    <div className="wrapper">
    <div id="snackbar"></div>
    <header className="">
      {/* <TopContactBar /> */}
      <Header />
    </header>
    <main className="">
     
    <Outlet />  
    </main>


  </div>
  )
}

export default Layout;