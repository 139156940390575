
import React, { useContext, useEffect, useState } from "react";

import c1icon from "../../../images/c1.png";
import c2icon from "../../../images/c2.png";
import c3icon from "../../../images/c3.png";
import c4icon from "../../../images/c4.png";
import c5icon from "../../../images/c5.png";
import banner from "../../../images/bannerimg2.png";
import play from "../../../images/play-circle.png";
import Button from "../../../components/ui/Button";
import { useNavigate } from "react-router-dom";
import { getStorage, setStorage } from "../../../Utils/common";
import ContextDashboard from "../../../Context/ContextDashboard";
import { DashboardWrapper } from "../style";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import DashboardCard2 from "../DashboardCard2";
import { getDashboardData } from "../../../Utils/api";
import { Helmet } from "react-helmet";

const cardList = [
  {
        heading: "Loan Calculator",
        desc: "Choose the loan amount and tenure.",
        img: c1icon,
        link: "/my-dashboard/calculate-loan",
      },
      {
        heading: "Employment Information",
        desc: "Share about your work status.",
        img: c2icon,
        link: "/my-dashboard/about-your-company",
      },
      {
        heading: "Upload Your Documents",
        desc: "Share your documents to verify your details",
        img: c4icon,
        link: "/my-dashboard/adhar-upload",
      },
      {
        heading: "Banking Details",
        desc: "Share your banking details",
        img: c2icon,
        link: "/my-dashboard/bank-detail",
      },
      
    ];



function Eligibility() {
  const [cards, setCards] = useState(cardList);
  const [stepComplate, setStepComplate] = useState(false);
  const [showSteps, setShowSteps] = useState(0);
  const [toggle, setToggle] = useState(true);
  const [progressBar,setProgressBar] = useState(0)
  const {setps} = useContext(ContextDashboard);

  const navigate = useNavigate();
  const {currentEvent} = useContext(ContextDashboard);

  const redirect = (data) =>{
    console.log(data);
    navigate(data.link)
  }

  
useEffect(() => {

  const generateClickId = () => {
    const now = new Date();
    return `${now.getFullYear()}${(now.getMonth() + 1).toString().padStart(2, '0')}${now.getDate().toString().padStart(2, '0')}_${now.getHours().toString().padStart(2, '0')}${now.getMinutes().toString().padStart(2, '0')}${now.getSeconds().toString().padStart(2, '0')}`;
  };
  // Trigger Facebook Pixel on component mount
  const sendFacebookPixelEvent = async () => {
    try {
      const response = await fetch(
          `https://www.facebook.com/tr?id=856557663119830&ev=PageView&noscript=1`
      );
      if (!response.ok) {
          console.error('Facebook Pixel event not sent: ', response.statusText);
      }
  } catch (error) {
      console.log('Error triggering Facebook Pixel: ', error);
  }
  };

   // Trigger Whistle Pixel of valueleaf
   const sendWhistlePixel = () => {
    const clickId = generateClickId();
    const img = new Image();
    img.src = `https://intexm.trackier.co/acquisition?click_id=${clickId}&security_token=19f7db61ebddbf3d4201&goal_value=Thankyou_page`;
  };
  sendWhistlePixel();

  // Call the pixel function
  sendFacebookPixelEvent();
}, []); // Empty dependency array to ensure it runs only once when the component mounts


useEffect(() => {
  const params = {
    profile_id: getStorage("cust_profile_id") || "",
  };

  getDashboardData(params).then(resp => {

    if (resp?.data?.Status === 1) {
      
      const dashboardData = resp?.data || {};
      setStorage('dashboardData', dashboardData);
      setStorage('percent',dashboardData?.Data?.application_filled_percent)
      setProgressBar(dashboardData?.Data?.application_filled_percent);
      setStorage('next_step',dashboardData?.Data?.next_event_name);
      setShowSteps(currentEvent(dashboardData?.Data?.next_event_name)-9)
    }
  });
}, []);

useEffect(()=>{
  const steps=currentEvent(getStorage("next_step"));
  setShowSteps(steps);
  
},[]);

  return (
    <>
    <Helmet>

       {/* value leaf pixel  */}
       <script>
          {`
            gtag('event', 'conversion', {'send_to': 'AW-16745229237/A4TuCN6El_IZELXX37A-'});
          `}
        </script>
        {/* end valueleaf pixel */}
                <script>
                  {`
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '856557663119830');
                fbq('track', 'Lead');

              `}
                </script>
                {/* Intexm pixel */}
                <script>
                  {`
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '684971346895514');
fbq('track', 'Lead');
`}
</script>

{/* <!-- Criteo Add to Cart Tag --> */}
<script type="text/javascript">
{`
  window.criteo_q = window.criteo_q || [];
  var deviceType = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";
  window.criteo_q.push(
    { event: "setAccount", account: 117850 },
    { event: "setEmail", email: "##Email Address of User##" },
    { event: "setSiteType", type: deviceType},
    { event: "addToCart", item: [
      {id: 1, price: 1, quantity: 1 }
    ]}
  );
  `}
</script>
{/* <!-- END Criteo Add to Cart Tag --> */}
                
              </Helmet>
    <DashboardWrapper>
      <ProgressBar value={`${progressBar}%`} title="Loan Application"  >
      </ProgressBar>

     {<div className="carde">
        {cards.map((value, index) => {
          return (
           <DashboardCard2
                number={index + 1}  // Adding the card number
                heading={value.heading}
                desc={value.desc}
                img={value.img}
                complate={(index + 1) <= showSteps}
                disable={index !== showSteps}
                key={index}
                onClick={() => redirect(value)}
              />
          );
        })}
       <div className="hideMD"></div>
      </div>
      
      }
      
    </DashboardWrapper>
    </>
  );
}

export default Eligibility;