import React, { useEffect, useState } from 'react';
import { BoxWrapper } from '../../../style';
import congratulations from "../../../images/congratulations.gif";
import { useNavigate } from 'react-router-dom';
import { getStorage } from '../../../Utils/common';
import { Helmet } from "react-helmet";

function ThankYou() {
  const navigate = useNavigate();
  const [progressBar, setProgressBar] = useState(getStorage('percent'));
  const [clickId1, setClickId1] = useState('');  // Initialize as empty string

  useEffect(() => {
    // Function to format the current date and time as a unique ID
    const generateClickId = () => {
      const now = new Date();
      return `${now.getFullYear()}${(now.getMonth() + 1).toString().padStart(2, '0')}${now.getDate().toString().padStart(2, '0')}_${now.getHours().toString().padStart(2, '0')}${now.getMinutes().toString().padStart(2, '0')}${now.getSeconds().toString().padStart(2, '0')}`;
    };

    const clickId2 = generateClickId(); 
    setClickId1(clickId2);  // Set the click ID in state

    // Trigger Facebook Pixel on component mount
    const sendFacebookPixelEvent = async () => {
      try {
        const response = await fetch(
          `https://www.facebook.com/tr?id=856557663119830&ev=PageView&noscript=1`
        );

        if (!response.ok) {
          console.error('Facebook Pixel event not sent: ', response.statusText);
        }
      } catch (error) {
        console.error('Error triggering Facebook Pixel: ', error);
      }
    };

    // Trigger Whistle Pixel of valueleaf
    const sendWhistlePixel = async (clickId) => {
      const revenue = '10.00'; // Example revenue value
      const img = new Image();
        img.src= `https://utils.follow.whistle.mobi/pixel.php?linkid=${clickId}&revenue=${revenue}&goal_name=Thankyou_page`;
    
      const img2=new Image();
      
        img2.src=  `https://intexm.trackier.co/acquisition?click_id=${clickId}&security_token=19f7db61ebddbf3d4201&goal_value=Application_Submit`


    };

    sendFacebookPixelEvent();
    sendWhistlePixel(clickId2);  // Pass the generated clickId directly

  }, []); // Empty dependency array to ensure it runs only once when the component mounts


  return (
    <div>
      <Helmet>
       

        <script>
          {`
          gtag('event', 'conversion', {
              'send_to': 'AW-16559892329/e17lCOSB8cwZEOnOr9g9',
              'value': 1.0,
              'currency': 'INR'
          });
          `}
        </script>

        <script>
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '856557663119830');
            fbq('track', 'SubmitApplication');
          `}
        </script>

        <script>
          {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '684971346895514');
          fbq('track', 'CompleteRegistration');
          `}
        </script>

        
      </Helmet>

   

      <img height="1" width="1" style={{ display: "none" }}
        src="https://www.facebook.com/tr?id=546967787828750&ev=PageView&noscript=1"
      />

      <BoxWrapper className="w100 gray">
        <div
          className="formmainBox flex"
          style={{
            backgroundImage: `url(${congratulations})`,
            backgroundSize: 'cover',
            height: '615px',
            width: '100%',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat'
          }}
        >
          <div className="left">
            {/* Any other content you want to show */}
          </div>
        </div>
      </BoxWrapper>
    </div>
  );
}

export default ThankYou;
