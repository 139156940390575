import React, { useState, useEffect } from "react";
import "../css/Common.css";
import { NavLink, Link, useNavigate } from "react-router-dom";
import logo from "../images/logo.png";
import { getStorage } from "../Utils/common";

const Header = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [eligibility, setEligibility] = useState(null); // To store eligibility status
  const navigate = useNavigate(); 

  useEffect(() => {
    const token = getStorage("token");
    const eligibilityStatus = getStorage("eligibility");

    if (token) {
      setIsLoggedIn(true);
    }

    if (eligibilityStatus) {
      setEligibility(eligibilityStatus);
    }
  }, []);

  function showNavbar(e) {
    let navbar = document.querySelector(".header_wrapper");
    let navList = document.querySelector(".nav_list");
    navList.classList.toggle("v-class");
    navbar.classList.toggle("h-nav");
    if (navbar.classList.contains("h-nav")) {
      navbar.style.zIndex = "1";
    } else {
      navbar.style.zIndex = "2";
    }
  }

  // Handle the "Dashboard" click event to navigate based on eligibility
  const handleDashboardClick = () => {
    if (eligibility === 1) {
      navigate("/my-dashboard/eligibility"); // Navigate to eligibility page if eligible
    } else {
      navigate("/my-dashboard"); // Navigate to the regular dashboard if not eligible
    }
  };

  return (
    <>
      <div className="header_wrapper h-nav">
        <nav>
          <div className="logo_wrapper">
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Header;
